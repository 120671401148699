import React from 'react';

export default function PageSection({ children, className, background = {} }) {
  // let background = {
  //   type: 'video or image or color',
  //   value: '#ffed30', // optional
  //   path: 'path to the file hosted or static does not matter' // optional // make sure in folder that will be accessible after deployment if static
  // }

  //Max width of content is 1280px/80rem, while backgrounds will span entire width of page
  //Children should not have backgrounds, backgrounds will be handled by this component
  return !background.type ? (
    <div className="">
      <section
        className={`page max-w-[1600px] mx-auto p-10 md:py-10 md:px-20 ${className || ' '} `}
        style={{ fontFamily: 'Josefin Sans' }}>
        {children} {/* max height of child should be the padding or margin added to the bottom + the offset desired */}
      </section>
    </div>
  ) : background.type === 'color' && background.value ? (
    <div className={`bg-${background.value}`}>
      <section
        className={`page max-w-[1600px] mx-auto p-10 md:py-10 md:px-20 ${className || ' '} `}
        style={{ fontFamily: 'Josefin Sans' }}>
        {children}
      </section>
    </div>
  ) : background.type === 'image' ? ( //WIP Do Not Use
    <div className={``} style={{}}>
      <section
        className={`page max-w-[1600px] mx-auto p-10 md:py-10 md:px-20 ${className || ' '} `}
        style={{ fontFamily: 'Josefin Sans' }}>
        {children}
      </section>
    </div>
  ) : (
    <div></div>
  );
}
