import React from 'react';

export default function Quote({ bigHeader, paragraph1, quote, paragraph2, paragraph3 }) {
  return (
    <div className="md:w-4/6 mx-auto">
      <object className="pb-5 w-12" data="/static/images/quoteMark.svg"></object>
      <h1 className="leading-normal font-bold text-4xl text-black" style={{ fontFamily: 'Oswald' }}>
        {bigHeader}
      </h1>
      <div className="py-5 text-gray-500">
        <p className="text-lg">{paragraph1}</p>
      </div>
      <div className="flex flex-row-2">
        <div className="bg-blue-500 w-1 rounded-md" />
        <div className="font-bold text-xl ml-1 text-black">{quote}</div>
      </div>
      <div className="py-5 text-gray-500">
        <p className="text-lg">{paragraph2}</p>
      </div>
      <div className="text-gray-500">
        <p className="text-lg">{paragraph3}</p>
      </div>
    </div>
  );
}
