import ChatbotHide from '../../components/ChatbotHide';
import CookieConsent from 'react-cookie-consent';
import Navbar from '../Navbar';
import Footer from '../Footer.js';

export default function LandingPage({ children, navType = 'static', landingNav = false }) {
  return (
    <>
      <head>
        <script src="/static/chatbot/index.js" async></script>
      </head>
      <CookieConsent
        id="cookie_consent"
        location="bottom"
        buttonText="Accept & Close"
        cookieName="cookie_terms_accepted"
        className="Higher"
        style={{ background: 'rgb(252, 189, 0)', fontFamily: 'Josefin Sans' }}
        expires={312}>
        <h1 className="fs-24-30 fw-400" style={{ fontFamily: 'Oswald', color: '#000000' }}>
          Cookie Policy
        </h1>
        <p className="fs-18-27 fw-400 mb-3 mb-md-4" style={{ fontFamily: 'Josefin Sans', color: '#363029' }}>
          This website uses cookies. By continuing to use this site, you accept our use of cookies.
        </p>
      </CookieConsent>
      <ChatbotHide show={false} debug={true} />
      <Navbar version={navType} landingNav={landingNav} />

      <div>
        <main className="bg-white mt-24 md:mt-0 text-[#1A1A1A]" style={{ fontFamily: 'Josefin Sans' }}>
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
}
