import React from 'react';

export default function TitleWithSideLines({ title }) {
  return (
    <div
      className="titleWithLineSideBySide text-4xl my-10 font-bold flex before:content-[''] before:flex-auto after:flex-auto before:m-auto after:m-auto after:content-[''] justify-center align-center before:border-b after:border-b before:border-solid after:border-solid before:border-black after:border-black before:mr-5 before:ml-2 after:ml-5 after:mr-2"
      style={{ fontFamily: 'Oswald' }}>
      {title}
    </div>
  );
}
