import {
  Modal,
} from 'rsuite';

export default function DiscoverRentalsModal({ show, setClose }) {

  return (
    <Modal style={{fontFamily:"Josefin Sans"}} className='w-[350px] sm:w-[600px] md:w-[800px]' backdrop={true} show={show} onHide={() => setClose(false)}>
      <Modal.Header>
        <Modal.Title className='font-bold'>Discover Rentals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="">
          <iframe
            id="JotFormIFrame-220127879505055"
            title="Get involved"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/221236025416243"
            frameborder="0"
            style={{ minWidth: '100%', height: '539px', border: 'none' }}
            scrolling="no"></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
}
