import React from 'react';

export default function HeaderParagraph({
  header,
  paragraph,
  boldText,
  textSizeClassName,
  paragraphTextSizeClassName,
}) {
  return (
    <div className="flex flex-col w-full pb-10">
      <div className="px-6 pb-5 text-left">
        <h1 className={`font-bold ${textSizeClassName || 'text-2xl'}`} style={{ fontFamily: 'Oswald' }}>{header}</h1>
      </div>
      <div className="px-6 text-left">
        <span className={`${paragraphTextSizeClassName || 'text-lg'} text-gray-500`}>{paragraph}</span>{' '}
        <span className={`${paragraphTextSizeClassName || 'text-lg'} text-gray-500 font-bold`}>{boldText}</span>
      </div>
    </div>
  );
}
