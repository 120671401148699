export default function Header({ title, className, textSizeClassName, marginYClassName, bold = true }) {
  return (
    <h1
      className={`title ${textSizeClassName || 'text-5xl'} leading-relaxed ${marginYClassName || 'my-10'}  ${
        bold ? 'font-bold' : ''
      } ${className || ' '}`}
      style={{ fontFamily: 'Oswald' }}>
      {title}
    </h1>
  );
}
