import React from "react";

export default function SectionBackgroundImage({  className, style, backgroundImageUrl  }) {
  return (
    <div
          className={`absolute h-full w-full inset-0 ${className}`}
          style={{ backgroundImage: `url(${backgroundImageUrl})`, zIndex: -1, ...style }}
        />
  );
}
