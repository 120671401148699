import TitleRegular from "../tailwindComponents/tempComponents/TitleRegular";
import LandingPage from "../tailwindComponents/Layouts/LandingPage";
import PageSection from "../tailwindComponents/tempComponents/PageSection";
import LinkButton from "../tailwindComponents/tempComponents/LinkButton";
import Paragraph from "../tailwindComponents/tempComponents/Paragraph";
import ParagraphWithTitleAndIcon from "../tailwindComponents/tempComponents/ParagraphWithTitleAndIcon";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DiscoverRentalsModal from "../components/modals/DiscoverRentals";
import { useState } from "react";
const ukranianImageLanding = "static/images/ukraine_landing.png";
const bridgingPeopleImage = "static/images/landingPages/bridgingPeople.png";
const laptopImage = "static/images/landingPages/laptopImage.png";

const UkraineLandingPage = () => {
  const [openDiscoverRentalsModal, setOpenDiscoverRentalsModal] =
    useState(false);

  return (
    <>
      <LandingPage>
        <PageSection className={"text-center flex flex-col"}>
          <TitleRegular
            title="A place to call home in times of crisis"
            marginYClassName="my-0"
            className="mt-20"
            textSizeClassName="text-7xl"
          />
          <span className="text-2xl ">
            An initiative to make rentals accessible to incoming ukrainians
          </span>
          <div className="image_and_background_container relative">
            <div className="image_container flex justify-center md:h-3/5 md:px-36 mt-10 md:mt-28 relative z-10">
              <img
                src={ukranianImageLanding}
                alt="4 people in front of building in ruins"
                className="border-4  border-black object-contain"
              />
            </div>
            <div className="yellow_bottom_background bg-[#ffb900] h-12 bottom-0 absolute left-0 w-full"></div>
          </div>
          <div className="yellow_bottom_background bg-[#ffb900] ">
            <TitleRegular
              className=" py-8 md:py-8 mx-10 md:mx-20"
              textSizeClassName="text-5xl"
              style={{ fontSize: "48px" }}
              title="Over 100,000 Ukrainians will leave their loved ones behind, and arrive on Canadian shores in 2022."
            ></TitleRegular>
            <TitleRegular
              className="  py-8 md:py-8  mx-10 md:mx-20"
              textSizeClassName="text-5xl"
              style={{ fontSize: "48px" }}
              title="This initiative is to help them find their new home."
            ></TitleRegular>
          </div>
        </PageSection>

        <PageSection className="text-center" style={{ padding: "0px" }}>
          <TitleRegular
            className=" py-8 md:py-8  mx-10 md:mx-20"
            textSizeClassName="text-5xl"
            style={{ fontSize: "48px", marginTop: "0px" }}
            title="Initiative supported by"
          />
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 items-center justify-items-center p-20">
            <a href={"https://www.rentsync.com/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/rentsync.png"></img>
              </div>
            </a>
            <a href={"https://vanhack.com/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/vanhack.png"></img>
              </div>
            </a>
            <a href={"https://www.1kproject.org/"} target="_blank">
              <div className="my-auto cursor-pointer w-36 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/k.jpeg"></img>
              </div>
            </a>
            <a href={"https://www.wisenup.ca/"} target="_blank">
              <div className="my-auto cursor-pointer w-36 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/wisenUp.jpeg"></img>
              </div>
            </a>
            <a href={"https://oneeleven.com/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/oneEleven.png"></img>
              </div>
            </a>
            <a href={"https://bunch.consulting/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/bunchConsulting.png"></img>
              </div>
            </a>
            <a href={"https://www.proptechcollective.com/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/ProptechCollectiveLogo.png"></img>
              </div>
            </a>
            <a href={"https://clik.ai/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/Clik.png"></img>
              </div>
            </a>
            <a href={"https://swtchenergy.com/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/SwitchEnergy.png"></img>
              </div>
            </a>
            <a href={"https://nar-reach.com/canada/"} target="_blank">
              <div className="my-auto cursor-pointer w-52 p-2.5">
                <img src="/static/tailwindAssets/Ukraine/Logos/ReachCanada.png"></img>
              </div>
            </a>
          </section>
        </PageSection>

        <PageSection className="text-center">
          <TitleRegular
            title="Bridging people and homes"
            textSizeClassName="text-5xl"
            className=" m-auto  py-8 md:py-8  mx-10 md:mx-20"
          />
          <section className="text_and_image_section flex flex-col lg:flex-row md:px-36  ">
            <div className="image_section flex-auto flex flex-col justify-center items-center md:basis-1/3 mb-10 md:mb-0 md:p-0">
              <img
                className="image_el flex-auto outline-0 object-cover"
                alt=""
                src={bridgingPeopleImage}
              />
            </div>
            <div className="text_section alignment_center flex-auto flex flex-col justify-center items-center md:basis-2/4 md:p-4 md:px-10">
              <Paragraph
                className="text-left"
                textColorCssClassName="text-black"
                style={{ fontSize: "23px" }}
              >
                As a technology company, we help rental managers make the most
                of their rentals. This initiative provides a single platform
                that empowers the voices of all rental housing providers and
                enables them to be at the forefront in helping the displaced
                Ukrainians arriving in Canada.
              </Paragraph>
              <Paragraph
                className="text-left"
                textColorCssClassName="text-black"
                style={{ fontSize: "23px" }}
              >
                We have partnered with relevant initiatives to ensure every unit
                and relief package offered reaches the right people in need.
                Together, weaving different parts of the public rental housing
                industry, we can help incoming Ukrainians find a place to call
                home.
              </Paragraph>
            </div>
          </section>
        </PageSection>

        <PageSection className="text-center md:pr-0">
          <TitleRegular
            title="One platform, many voices"
            textSizeClassName="text-5xl"
            className=" m-auto  py-8 md:py-8  mx-10 md:mx-20"
          />
          <section className="text_and_image_section flex flex-col lg:flex-row md:px-36 md:pr-0  bg-contain bg-right bg-no-repeat">
            <div className="text_section alignment_center flex-auto flex flex-col justify-center items-center md:basis-2/4 md:p-4 md:px-0">
              <ParagraphWithTitleAndIcon
                title="Discover"
                fontIcon={faStar}
                className="my-4"
                headerCustomClassName="pb-4"
              >
                Our partnerships with the Ukrainian Canadian Congress, and other
                organizations in Poland and Ukraine, will ensure awareness about
                listed offers among incoming Ukrainians.
              </ParagraphWithTitleAndIcon>
              <ParagraphWithTitleAndIcon
                title="Apply"
                fontIcon={faStar}
                className="my-4"
                headerCustomClassName="pb-4"
              >
                Our platform is designed to allow immigrants to submit
                applications seamlessly. We also integrate with other popular
                industry software.
              </ParagraphWithTitleAndIcon>
              <ParagraphWithTitleAndIcon
                title="Approve"
                fontIcon={faStar}
                className="my-4"
                headerCustomClassName="pb-4"
              >
                Review and approve applicants remotely, ensuring a home before
                landing on Canadian shores.
              </ParagraphWithTitleAndIcon>
            </div>
            <div className="image_section flex-auto hidden lg:flex flex-col justify-center items-center  lg:basis-2/3 lg:p-0">
              <img
                className="image_el flex-auto outline-0 object-contain"
                alt=""
                src="/static/tailwindAssets/Get Involved/product snapshots.png"
              />
            </div>
          </section>
        </PageSection>

        <div className="bg-[#ffb900]">
          <PageSection className="text-center">
            {openDiscoverRentalsModal && (
              <DiscoverRentalsModal
                show={openDiscoverRentalsModal}
                setClose={setOpenDiscoverRentalsModal}
              />
            )}
            <TitleRegular
              textSizeClassName="text-5xl"
              title="Together, we will make a difference."
              className=" m-auto   py-8 md:py-8  mx-10 md:mx-20"
            />
            <section className="flex flex-col md:flex-row justify-center items-center pb-20">
              <LinkButton
                className="bg-black md:mx-10 my-4"
                onClick={() => (window.location.href = "/get-involved")}
                smallPadding={true}
                style={{ fontSize: "23px", height: "50px" }}
              >
                Get Involved
              </LinkButton>
              <LinkButton
                className="bg-black md:mx-10 my-4"
                smallPadding={true}
                onClick={() => setOpenDiscoverRentalsModal(true)}
                style={{ fontSize: "23px", height: "50px" }}
              >
                Discover Rentals
              </LinkButton>
            </section>
          </PageSection>
        </div>
      </LandingPage>
    </>
  );
};

export default UkraineLandingPage;
