import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paragraph from './Paragraph';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default function ParagraphWithTitleAndIcon({
  children,
  fontIcon,
  title,
  className,
  alignAllVertically,
  iconCustomClassName,
  iconImageUrl,
  headerCustomClassName,
  headerCustomTextSizeClassName,
  paragraphCustomClassName,
  smallerTextForParagraph,
  readMoreUrl,
  readMoreLinkCustomClassName,
}) {
  const computerParagraphClassName = ` ${!alignAllVertically && ' md:mr-12 '} mt-2  `;
  return (
    <section className={`my-4 ${className}`}>
      <div className={`flex ${alignAllVertically ? 'flex-col' : 'flex-row '} `}>
        {fontIcon && (
          <div className="image_container flex flex-col justify-start">
            <span
              className={`rounded-full shadow-lg border w-16 h-16 flex bg-white items-center justify-center ${!alignAllVertically &&
                ' mr-8 '} ${iconCustomClassName}`}>
              <FontAwesomeIcon size="2xl" icon={fontIcon} />
            </span>
          </div>
        )}

        {iconImageUrl && (
          <img
            src={iconImageUrl}
            className={` w-16 h-16 flex bg-white items-center justify-center ${!alignAllVertically &&
              ' mr-8 '} ${iconCustomClassName}`}
          />
        )}
        <div className="title_and_paragraph_container text-left">
          <h1
            className={` font-bold ${headerCustomTextSizeClassName || 'text-3xl'}  my-0  ${headerCustomClassName}`}
            style={{ fontFamily: 'Fjalla One' }}>
            {title}
          </h1>

          <Paragraph
            smaller={smallerTextForParagraph}
            className={`text-left ${computerParagraphClassName}  ${paragraphCustomClassName}`}>
            {children}
          </Paragraph>
          {readMoreUrl && (
            <a
              style={{ fontFamily: 'Josefin Sans' }}
              href={readMoreUrl}
              className={`flex flex-row ${
                smallerTextForParagraph ? 'text-lg' : 'text-xl'
              } text-black items-center  ${computerParagraphClassName}  ${readMoreLinkCustomClassName}`}>
              <span className="mr-2">Learn more</span> <FontAwesomeIcon icon={faAngleRight} />
            </a>
          )}
        </div>
      </div>
    </section>
  );
}
