import React from 'react';
import TitleRegular from './TitleRegular';
import TitleLookalikeButton from './TitleLooklikeButton';
import PageSection from './PageSection';
import LinkButton from './LinkButton';
import SectionBackgroundImage from './SectionBackgroundImage';

export default function PageSectionWithImageAndText({
  className,
  title,
  style,
  buttonTitle,
  actionButtonLabel,
  actionButtonOnClick,
  actionButtonClassName,
  actionButtonCustomPaddingClassName,
  imageUrl,
  hideImageOnSmallScreen,
  showImageOnLeft,
  fullscreen,
  noMarginNextToImage,
  titleCustomClassName,
  increaseImageSize,
  backgroundImageUrl,
  backgroundImageClassName,
  actionButtonHasLargePadding,
  addScale = false,
  children,
}) {
  return (
    <PageSection
      style={style}
      className={`text-center ${noMarginNextToImage && (showImageOnLeft ? ' md:pl-10 ' : ' md:pr-10 ')} ${className}`}>
      {backgroundImageUrl && (
        <SectionBackgroundImage backgroundImageUrl={backgroundImageUrl} className={backgroundImageClassName} />
      )}
      <section
        className={`text_and_image_section flex flex-col ${
          showImageOnLeft ? ' lg:flex-row-reverse ' : ' lg:flex-row '
        }   ${fullscreen && 'lg:h-screen'} `}>
        <div
          className={`text_section alignment_center text-left flex-auto flex flex-col justify-center align-center  ${
            increaseImageSize ? 'md:basis-1/3' : 'md:basis-2/4'
          }  ${!increaseImageSize ? 'md:p-28' : ''}`}>
          {buttonTitle && <TitleLookalikeButton title={buttonTitle} />}

          {title && <TitleRegular title={title} className={titleCustomClassName} style={{ lineHeight: 1.4 }} />}
          {children}
          {actionButtonLabel && (
            <LinkButton
              className={`mt-10 ${actionButtonClassName || ''}`}
              onClick={actionButtonOnClick}
              customPaddingClassName={actionButtonCustomPaddingClassName}
              smallPadding={!actionButtonHasLargePadding}
              style={{ fontSize: '23px', height: '52px', flex: '0 0 52px' }}>
              {actionButtonLabel}
            </LinkButton>
          )}
        </div>

        <div
          className={`image_section ${showImageOnLeft ? ' mb-10 md:mb-0 ' : ' mt-10 md:mt-0 '}  ${
            hideImageOnSmallScreen ? ' hidden lg:flex ' : ' flex '
          } flex-auto flex-col justify-center align-center
            ${increaseImageSize ? 'md:basis-2/3' : 'md:basis-2/4'}
            ${noMarginNextToImage ? ' md:p-0 ' : ' md:p-2 '} `}>
          <img
            className={`image_el flex-auto
            ${addScale ? 'scale-150' : ''}
            ${showImageOnLeft ? 'pl-0' : 'pr-0'}
            ${!imageUrl && 'bg-slate-100'}  outline-0 object-contain`}
            alt=""
            src={imageUrl}
          />
        </div>
      </section>
    </PageSection>
  );
}
