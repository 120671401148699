import React from 'react';

export default function VerticalImageText({ imgSrc, header, paragraph, withShadow, customStyle, customChildrenStyle }) {
  return (
    <div
      className={`flex flex-col w-full items-center rounded-md ${customStyle || ''} ${withShadow ? 'shadow-[0_0_35px_-15px_rgba(0,0,0,0.3)]' : ''}`}>
      <div className={`p-2.5 ${customChildrenStyle || ''}`}>
        <div className="mx-auto">
          <object className="pb-5 max-w-full mx-auto" data={imgSrc}></object>
        </div>
        <div className="px-5 pb-5">
          <h1 className="font-bold text-[26px] md:text-[28px] text-black" style={{ fontFamily: 'Oswald' }}>
            {header}
          </h1>
        </div>
        <div className="px-5">
          <p className="text-center text-[20px] text-gray-500">{paragraph}</p>
        </div>
      </div>
    </div>
  );
}
