import React from 'react';

export default function PageSection({ children, className, paddingClassName, style }) {
  return (
    <section
      className={`page  max-w-[1600px] mx-auto ${paddingClassName || 'p-10 md:py-10 md:px-20'} relative ${className ||
        ' '} `}
      style={{ zIndex: 0, fontFamily: 'Josefin Sans', ...style }}>
      {children}
    </section>
  );
}
