import React from 'react';

export default function TitleRegular({
  title,
  className,
  textSizeClassName,
  textColorClassName,
  marginYClassName,
  style,
}) {
  const computedStyle = { fontFamily: 'Oswald' };
  if (!textSizeClassName) {
    computedStyle['fontSize'] = '40px';
  }
  return (
    <h1
      className={`title ${textSizeClassName || 'text-4xl'} ${textColorClassName ||
        ''}  leading-relaxed ${marginYClassName || 'my-10'}  font-bold ${className || ' '}`}
      style={{ ...computedStyle, ...style }}>
      {title}
    </h1>
  );
}
