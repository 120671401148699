import React, { useState } from "react";
import TeamMemberCard from "../tailwindComponents/tempComponents/TeamMemberCard";
import TeamMemberCardSmall from "../tailwindComponents/tempComponents/TeamMemberCardSmall";
import TitleLookalikeButton from "../tailwindComponents/tempComponents/TitleLooklikeButton";
import TitleWithSideLines from "../tailwindComponents/tempComponents/TitleWithSideLines";
import TitleRegular from "../tailwindComponents/tempComponents/TitleRegular";
import PageSection from "../tailwindComponents/tempComponents/PageSection";
import LinkButton from "../tailwindComponents/tempComponents/LinkButton";
import Paragraph from "../tailwindComponents/tempComponents/Paragraph";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PageSectionWithImageAndText from "../tailwindComponents/tempComponents/PageSectionWithImageAndText";
import PageSectionWithTwoColumns from "../tailwindComponents/tempComponents/PageSectionWithTwoColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import LandingPage from "../tailwindComponents/Layouts/LandingPage";
import Header from "../tailwindComponents/Header";
import { UserApi } from "../services/api/user";
import { Alert } from "rsuite";
const greyImage = "static/images/landingPages/story.jpeg";
const mapImage = "static/images/landingPages/mapImage.png";

const AboutUsPage = () => {
  // contact form
  const [nameOnContactForm, setNameOnContactForm] = useState(null);
  const [emailOnContactForm, setEmailOnContactForm] = useState(null);
  const [messageOnContactForm, setMessageOnContactForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const sendEmail = () => {
    setLoading(true);
    if (
      nameOnContactForm &&
      emailOnContactForm &&
      validateEmail(emailOnContactForm) &&
      messageOnContactForm
    ) {
      //send email
      UserApi.contactUs({
        nameOnContactForm,
        emailOnContactForm,
        messageOnContactForm,
      })
        .then(() => {
          setTimeout(() => {
            Alert.success("Message Sent!");
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            Alert.error("Failed to send message!");
            console.error(err);
            setLoading(false);
          }, 500);
        });
      setNameOnContactForm("");
      setEmailOnContactForm("");
      setMessageOnContactForm("");
    } else {
      //missing input
      Alert.error("Invalid Input!");
      setLoading(false);
    }
  };

  const mainTeamMembers = [];
  const createTeamMember = function (
    profileImageUrl,
    fullName,
    jobTitle,
    facebookProfileUrl,
    instagramProfileUrl,
    twitterProfileUrl,
    linkedinProfileUrl
  ) {
    return {
      profileImageUrl,
      fullName,
      jobTitle,
      linkedinProfileUrl,
    };
  };
  mainTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/arnab.jpeg",
      "Arnab Dastidar",
      "Co-founder",
      "facebookProfileUrl",
      "instagramProfileUrl",
      "twitterProfileUrl",
      "https://ca.linkedin.com/in/arunabhadastidar"
    )
  );
  mainTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/gaurav.jpeg",
      "Gaurav Madani",
      "Co-founder",
      "facebookProfileUrl",
      "instagramProfileUrl",
      "twitterProfileUrl",
      "https://ca.linkedin.com/in/gauravmadani"
    )
  );
  mainTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/zain.jpg",
      "Zain Nathoo",
      "Co-founder",
      "facebookProfileUrl",
      "instagramProfileUrl",
      "twitterProfileUrl",
      "https://ca.linkedin.com/in/zain-nathoo"
    )
  );

  const otherTeamMembers = [];

  otherTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/karl.jpeg",
      "Karl",
      "Software Developer"
    )
  );
  otherTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/zack.jpeg",
      "Zack",
      "Software Developer"
    )
  );
  otherTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/leon.jpeg",
      "Leon",
      "Tech Lead"
    )
  );
  otherTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/volkan.jpeg",
      "Volkan",
      "Software Developer"
    )
  );
  otherTeamMembers.push(
    createTeamMember(
      "static/images/landingPages/adithya.jpeg",
      "Adithya",
      "Research Intern"
    )
  );

  const carouselImages = [greyImage];

  return (
    <>
      <LandingPage navType="static">
        <div className="pt-20">
          <PageSectionWithImageAndText
            title="On a mission to transform multi-family real estate"
            buttonTitle="About"
            imageUrl="static/images/landingPages/company.jpg"
            hideImageOnSmallScreen={false}
            fullscreen={false}
            titleCustomClassName=" md:max-w-lg"
            increaseImageSize={false}
            noMarginNextToImage={false}
          >
            <Paragraph style={{ fontSize: "23px" }}>
              We're redefining multi-family real estate to being automated and
              data-driven, making it easier for rental managers to make
              decisions.
            </Paragraph>
          </PageSectionWithImageAndText>
        </div>

        <TitleWithSideLines title="The SoulRooms story" />

        <PageSection>
          <section className="text_and_image_section flex flex-col lg:flex-row lg:h-screen ">
            <div className="image_section alignment_center flex-auto flex flex-col justify-top items-center md:basis-2/4 md:p-10 mb-10 md:mb-0">
              <Carousel
                showArrows={true}
                showIndicators={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
              >
                {carouselImages.map((imageLogo, index) => (
                  <img
                    src={imageLogo}
                    className="image_el flex-auto bg-slate-100 outline-0"
                    alt=""
                  />
                ))}
              </Carousel>
            </div>
            <div className="text_section alignment_start flex-auto flex flex-col justify-start align-start md:basis-2/4 md:p-10">
              <Paragraph style={{ fontSize: "23px" }}>
                The SoulRooms Team combines their deep experience in real estate
                (JLL, Cushman & Wakefield and Greybook) alongside with their
                experience in banking and technology(Goldman Sachs, RBC &
                Zomato) and applies it to the world of SoulRooms.
              </Paragraph>
              <Paragraph style={{ fontSize: "23px" }}>
                Rental managers are faced with unique challenges and we set out
                to build a digital solution that automates and orients their
                operations in data.
              </Paragraph>
              <Paragraph style={{ fontSize: "23px" }}>
                We started SoulRooms from the founders’ personal experiences in
                the domain to tackle the modern-day challenges that rental
                managers face by enabling them to leverage their data and
                systems uniquely. So far, we have seen great success in making
                rental management better for institutions and would continue to
                do so with all your love :)
              </Paragraph>
              <ul
                className="arrow_list  mb-5  text-gray-500"
                style={{ fontSize: "23px" }}
              >
                <li className="arrow_list_item before:content-['➤'] before:px-2 py-2">
                  Over 2000 subscribed units
                </li>
                <li className="arrow_list_item  before:content-['➤'] before:px-2 py-2">
                  Deployed in 40 cities across North America
                </li>
                <li className="arrow_list_item  before:content-['➤'] before:px-2 py-2">
                  $10 billion in Assets Under Management(AUM)
                </li>
              </ul>
              <LinkButton
                onClick={() => (window.location = "#contact-us-form")}
              >
                Media and Partnership Contact
              </LinkButton>
            </div>
          </section>
        </PageSection>

        <PageSection>
          <section className="text_section md:px-32">
            <section className="text_section_with_header md:max-w-5xl ">
              <TitleLookalikeButton title="Our Team" />
              <TitleRegular
                title="Fearless, experienced, driven. That's our team."
                marginYClassName="my-5"
              />
              <Paragraph
                className="md:max-w-2xl"
                paddingClassName="mb-10"
                style={{ fontSize: "23px" }}
              >
                We're a group of industry experts on a mission to improve the
                multi-family real estate experience in everything we do.
              </Paragraph>
            </section>

            <section className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 mb-10   ">
              {mainTeamMembers.map((m, index) => (
                <TeamMemberCard {...m} key={index} />
              ))}
            </section>

            <section className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 ">
              {otherTeamMembers.map((m, index) => (
                <TeamMemberCardSmall {...m} key={index} />
              ))}
            </section>
          </section>
        </PageSection>

        <PageSection className={"my-20"}>
          <section className="text_section md:p-10 md:px-32">
            <TitleLookalikeButton title="Our Clients" />
            <TitleRegular title="Who uses SoulRooms?" />
          </section>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 mx-auto mb-20 md:px-32">
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/interrentImg.png"}
            />
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/trulifeImg.png"}
            />
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/akeliusImg.png"}
            />
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/barneyRiverImg.png"}
            />
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/zahraPropImg.png"}
            />
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/cogirImg.png"}
            />
            <img
              className="place-self-center col-span-1"
              src={"/static/tailwindAssets/EstateManagers/harringtonImg.png"}
            />
          </div>
        </PageSection>

        <div id="contact-us-form" style={{ backgroundColor: "rgb(0,43,70)" }}>
          <PageSectionWithTwoColumns
            fullscreen={true}
            style={{ backgroundColor: "rgb(0,43,70)" }}
            className="text-white"
          >
            <section className="flex flex-col md:px-20">
              <TitleRegular
                title="Let's Connect"
                textColorClassName="text-white"
                marginYClassName="mb-5"
              />
              <Paragraph
                className="md:max-w-2xl text-white"
                style={{ fontSize: "23px" }}
              >
                Send us a line or two! We love to chat about all things
                Multifamily.
              </Paragraph>
              <section className="form text-xl " style={{ fontSize: "23px" }}>
                <section className="input_group grid grid-cols-1 md:grid-cols-2 mb-10">
                  <div className="formItem flex flex-col  flex-auto md:pr-6">
                    <label htmlFor="name">Name:</label>
                    <input
                      name="name"
                      placeholder="Your Name"
                      className="rounded h-12 px-4 text-black"
                      value={nameOnContactForm}
                      onChange={(event) =>
                        setNameOnContactForm(event.target.value)
                      }
                    />
                  </div>
                  <div className="formItem flex flex-col   flex-auto ">
                    <label htmlFor="name">E-mail:</label>
                    <input
                      name="name"
                      type={"email"}
                      className="rounded h-12 text-black px-4"
                      value={emailOnContactForm}
                      onChange={(event) =>
                        setEmailOnContactForm(event.target.value)
                      }
                      placeholder="email@email.com"
                    />
                  </div>
                </section>
                <textarea
                  placeholder="Your question..."
                  className="py-4 h-52 px-4 text-black  w-full rounded"
                  onChange={(event) =>
                    setMessageOnContactForm(event.target.value)
                  }
                  value={messageOnContactForm}
                ></textarea>
              </section>

              <LinkButton
                className="mt-10"
                disabled={loading}
                smallPadding={true}
                onClick={sendEmail}
              >
                <span style={{ fontSize: "23px" }}>Send</span>
                <span>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faPaperPlane}
                    className="pl-4"
                  />
                </span>
              </LinkButton>
            </section>
            <section>
              <div
                className={`image_el flex-auto h-full ${
                  !mapImage && "bg-slate-100"
                }  outline-0 object-contain`}
              >
                <iframe
                  width="100%"
                  height="600"
                  frameborder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%20325%20Front%20Street%20West,%20Toronto,%20Ontario%20M5V%202%20Y1,%20Canada+(SoulRooms%20Inc.)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.gps.ie/wearable-gps/">
                    wearable trackers
                  </a>
                </iframe>
              </div>
            </section>
          </PageSectionWithTwoColumns>
        </div>
      </LandingPage>
    </>
  );
};

export default AboutUsPage;
