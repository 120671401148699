import React from 'react';

export default function TeamMemberCardSmall({ profileImageUrl, fullName, jobTitle }) {
  return (
    <div className="team_member_profile_small w-72">
      <div className="profile_image_container flex">
        <img src={profileImageUrl} className="image_el object-contain flex-auto outline-0" alt="" />
      </div>
      <section className="user_info p-4">
        <div className="member_name text-xl text-black py-4" style={{ fontFamily: 'Fjalla One' }}>
          {fullName}
        </div>
        <div className="job_title  py-2  text-lg text-gray-500">{jobTitle}</div>
        <section className="social_info_list"></section>
      </section>
    </div>
  );
}
