/**
 * Axios Request Wrapper
 */

import axios from 'axios';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: '/',
  withCredentials: true,
});

export default client;
