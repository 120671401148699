import React from "react";

export default function LinkButton({ children, className , style, smallPadding, customPaddingClassName, onClick, disabled = false}) {
  return (
    <button
    onClick={onClick}
    style={style}
    disabled={disabled}
      className={`link_button flex text-xl flex-row flex-initial items-center justify-center ${customPaddingClassName || (smallPadding? "p-2 px-4": "p-4 px-8")} bold  ${disabled ? `bg-gray-400` :`bg-yellow-500`} text-white w-fit rounded-md ${
        className || " "
      }`}
    >
      {children}
    </button>
  );
}
