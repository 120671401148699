import LandingPage from '../tailwindComponents/Layouts/LandingPage';
import Stats from '../tailwindComponents/Cards/Stats';
import Button from '../tailwindComponents/Button';
import Quote from '../tailwindComponents/Quote';
import VerticalImageText from '../tailwindComponents/Cards/VerticalImageText';
import ImageTitleLink from '../tailwindComponents/Cards/ImageTitleLink';
import HeaderParagraph from '../tailwindComponents/Cards/HeaderParagraph';
import React from 'react';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import PageSection from '../tailwindComponents/PageSection';
import Header from '../tailwindComponents/Header';
import Paragraph from '../tailwindComponents/Paragraph';
import ParagraphWithTitleAndIcon from '../tailwindComponents/ParagraphWithTitleAndIcon';
import detectWindowScreenSize from '../utils/detect-windows-size';
import PageSectionWithImageAndText from '../tailwindComponents/tempComponents/PageSectionWithImageAndText';
import LinkButton from '../tailwindComponents/tempComponents/LinkButton';

export default function GetInvolved() {
  const [width] = detectWindowScreenSize();
  return (
    <LandingPage>
      <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Soulrooms - Get Involved</title>
      </head>
      <div>
        <PageSection className={''}>
          <section className="text_and_image_section flex flex-col lg:flex-row md:h-screen ">
            <div className="text_section alignment_center flex-auto flex flex-col justify-center align-center md:basis-2/4 md:p-28">
              <div className="image_section flex-auto flex lg:hidden flex-col justify-center align-center md:basis-2/4 md:p-10">
                <img src="/static/tailwindAssets/Hero/ukraineHeroRight.png" />
              </div>
              <Header
                textSizeClassName="text-[48px]"
                title="Give Ukrainians a place to call home"
                bold={false}
                className=" md:max-w-lg text-center text-black md:text-left"
              />
              <Paragraph textSizeClassName="text-[23px]" className="text-center md:text-left">
                Band together with other good Samaritans of the Canadian rental industry and help incoming Ukrainians
                take that first step towards a better life in Canada.
              </Paragraph>
              <div className="flex w-15 justify-center md:justify-start">
                <button
                  onClick={() => (window.location = '#get-involved-iframe')}
                  type="button"
                  className="bg-yellow-500 text-white py-4 px-12 w-[286px] h-[67px] text-[24px] rounded-md ">
                  {'Get Involved'}
                </button>
              </div>
            </div>
            <div className="image_section flex-auto hidden lg:flex flex-col justify-center align-center md:basis-2/4 md:p-10">
              <img src="/static/tailwindAssets/Hero/ukraineHeroRight.png" />
            </div>
          </section>
        </PageSection>

        <PageSection className="text-center">
          <Header
            className=" py-8 md:py-8 mx-10 md:mx-20 text-black"
            textSizeClassName="text-3xl md:text-5xl"
            title="Take that First Step"
          />
          <section className="flex flex-col md:flex-row align-center justify-center">
            <HeaderParagraph
              textSizeClassName="text-lg md:text-2xl text-black"
              paragraphTextSizeClassName="text-sm md:text-xl"
              header={'100,000'}
              paragraph={
                'At a minimum, this is the number of Ukrainians expected to arrive on Canadian shores in 2022 on a special temp visa'
              }
              boldText={'that does not promise them benefits typically available to refugees.'}
            />
            <HeaderParagraph
              textSizeClassName="text-lg md:text-2xl text-black"
              paragraphTextSizeClassName="text-sm md:text-xl"
              header={'25,000'}
              paragraph={'Homes that would be required to accommodate the incoming Ukrainians.'}
              boldText={'The government cannot meet this need which warrants that other private players step up. '}
            />
            <HeaderParagraph
              textSizeClassName="text-lg md:text-2xl text-black"
              paragraphTextSizeClassName="text-sm md:text-xl"
              header={'Now'}
              paragraph={
                'It is time to put your hand up and make a difference in the lives of people arriving in Canada from war-torn Ukraine.'
              }
              boldText={'Together we can help them lay the foundations in their new home.'}
            />
          </section>

          <hr className=" mx-auto my-0 border-black" />

          <section className="flex flex-col mt-20 mb-5 md:flex-row align-center justify-center md:space-x-16">
            <Stats statNum={43} statTitle={'Rental Managers Involved'} />
            <Stats statNum={154} statTitle={'Rentals Committed'} />
            <Stats statNum={99} statTitle={'Special Offers'} />
          </section>
        </PageSection>
        <PageSection className={'mb-20'} background={{ type: 'color', value: 'yellow-500' }}>
          <div className="pt-10 text-center">
            <p
              className="text-xl md:text-3xl  px-10 text-black font-medium mb-10 "
              style={{ fontFamily: 'Oswald', lineHeight: 1.5 }}>
              The above data is updated regularly based on the commitments received from the Canadian housing industry. 
SoulRooms Discover Rentals page, dedicated to this campaign, will activate for the Ukrainian accommodation seekers once a substantial number of rentals are committed to this initiative by the housing partners. 
At that point, our on-ground partners in Ukraine and Poland will also double down on the efforts to encourage local participation for applications from renters arriving in Canada.
            </p>
            <div className="text-xl">
              <LinkButton
                onClick={() => (window.location = '#get-involved-iframe')}
                className="bg-black mx-auto text-2xl w-72">
                Get Involved
              </LinkButton>
            </div>
          </div>
        </PageSection>
        <PageSection className={'mb-20 px-4 md:px-20'}>
          <Quote
            bigHeader={'Our mission is to unlock the full potential of Canadian landlords to combat this crisis'}
            quote={
              'No contribution is too small, nor is any commitment too big. This initiative is designed to accommodate all meaningful offers that can be made available to make rental accommodation accessible to incoming Ukrainians.'
            }
            paragraph1={
              'With 100,000 Ukrainians arriving on Canadian shores this year, operating in silence and silos won’t cut it. This is a call to come together and amplify the difference we can make as an industry in helping Ukrainians find new homes.'
            }
            paragraph2={
              'We are incredibly grateful to all those from the industry who have already put their hand up and led by example. Our commitment is to make the SoulRooms platform available at no cost to help displaced incoming Ukrainians discover, apply and secure rental accommodation from rental providers they can trust across Canada.'
            }
            paragraph3={
              'We aim to consolidate the siloed efforts of all Canadian landlords looking to help displaced Ukrainian families looking to find a new life in Canada through our platform.'
            }
          />
        </PageSection>

        <PageSection className="text-center">
          <Header
            className=" md:py-8  mx-10 md:mx-20"
            textSizeClassName="text-3xl md:text-5xl text-black"
            title="Offers from participating rental managers"
          />
          <div className="grid grid-cols-1 md:grid-cols-3 mx-auto mb-20 ">
            <VerticalImageText
              imgSrc={'/static/tailwindAssets/Icon /discountedRent.svg'}
              header={'Discounted rents'}
              paragraph={`Waive the first few months of rent 
            or discount overall rent by a percentage. A little help will go a long way in making rentals accessible.`}
            />
            <VerticalImageText
              imgSrc={'/static/tailwindAssets/Icon /depositRequirement.svg'}
              header={'Waived deposit requirements'}
              paragraph={
                'The most important thing for incoming Ukrainians would likely be the safety net of savings in their pocket. Help them until they find their feet in Canada.'
              }
            />
            <VerticalImageText
              imgSrc={'/static/tailwindAssets/Icon /freeFurniture.svg'}
              header={'Free furniture in unit'}
              paragraph={
                'Support with basic household essentials such as beds, kitchen items etc., would help ease the challenge of trying to turn their house into a home. '
              }
            />
          </div>
        </PageSection>
        <PageSectionWithImageAndText
          title="Join the initiative"
          noMarginNextToImage={true}
          hideImageOnSmallScreen={true}
          // addScale={true}
          imageUrl={`/static/tailwindAssets/Get Involved/product snapshots.png`}
          fullscreen={false}>
          <ParagraphWithTitleAndIcon
            title="Talk to our team"
            fontIcon={faStar}
            className="my-4 text-black"
            textSizeClassName="text-xl md:text-3xl"
            paragraphTextSizeClassName="text-md md:text-xl">
            Schedule a call with us to determine the best way for your properties to be listed.
          </ParagraphWithTitleAndIcon>
          <ParagraphWithTitleAndIcon
            title="Commit rentals"
            fontIcon={faStar}
            className="my-4 text-black"
            textSizeClassName="text-xl md:text-3xl"
            paragraphTextSizeClassName="text-md md:text-xl">
            Allocate properties and design offers you deem fit for the cause.
          </ParagraphWithTitleAndIcon>
          <ParagraphWithTitleAndIcon
            title="Accept applicants"
            fontIcon={faStar}
            className="my-4 text-black"
            textSizeClassName="text-xl md:text-3xl"
            paragraphTextSizeClassName="text-md md:text-xl">
            Review and accept applications from interested prospects. You can count on our support at every step of this
            journey.
          </ParagraphWithTitleAndIcon>
        </PageSectionWithImageAndText>
        <PageSection className="text-center">
          <Header className="text-black" textSizeClassName="text-3xl md:text-5xl" title="Get Involved" />
        </PageSection>
        <div id="get-involved-iframe" className="mb-20 ">
          <iframe
            id="JotFormIFrame-220127879505055"
            title="Get involved"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/221036796960058"
            frameborder="0"
            style={{ minWidth: '100%', height: '539px', border: 'none' }}
            scrolling="no"></iframe>
        </div>
        <PageSection>
          <Header textSizeClassName="text-5xl text-black text-center" title="Other ways to help" />
          <div className="grid grid-cols-1 gap-4 place-items-center md:grid-cols-3 mb-20">
            <ImageTitleLink
              imgSrc={'/static/tailwindAssets/Icon /donation.png'}
              header={'Make a donation'}
              link={
                'https://medium.com/soulrooms-inc/heres-a-list-of-the-top-rated-charities-to-help-the-ukraine-relief-effort-c408e147905b'
              }
            />
            <ImageTitleLink
              imgSrc={'/static/tailwindAssets/Icon /listAJob.png'}
              header={'List a job'}
              link={'https://www.jobbank.gc.ca/findajob/resources/jobsforukraine'}
            />
            <ImageTitleLink
              imgSrc={'/static/tailwindAssets/Icon /essentials.png'}
              header={'Help with essentials'}
              link={'https://www.centrefornewcomers.ca/supportukraine'}
            />
          </div>
        </PageSection>
      </div>
    </LandingPage>
  );
}
