import React, { useState } from 'react';
import { UserApi } from '../services/api/user';
import { Alert } from 'rsuite';
import { Link } from 'react-router-dom'

export default function Footer() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false)

  const subscribeEmail = () => {
    if (email) {
      setLoading(true);
      //Subscribe API Call
      UserApi.subscribe({ email })
        .then(() => {
          Alert.success('Subscribed!');
          setTimeout(() => {
            setEmail('');
            setLoading(false);
          }, 500);
        })
        .catch(err => {
          Alert.error('Failed to subscribe!');
          console.error(err);
          setEmail('');
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="bg-black">
        <div
          className="max-w-[1600px] mx-auto text-gray-300 flex flex-col text-base md:text-xl"
          style={{ fontFamily: 'Josefin Sans' }}>
          <div className="grid lg:grid-cols-5 grid-cols-2 mx-2 mb-8 p-4">
            <object
              aria-label="Soulrooms Logo"
              className="col-span-2 lg:col-span-1 mb-4 bg-white py-2 px-4 lg:mt-4"
              data="/static/tailwindAssets/Container / _Logo.svg"></object>
            <div className="flex flex-col mb-4 lg:mt-4">
              <div className="font-extrabold mb-6" style={{ fontFamily: 'Oswald' }}>
                Product
              </div>
              <a href="https://www.soulrooms.com/analytics">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit mb-1">Analytics</a>
              </a>
              <a href="https://www.soulrooms.com/convert">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit mb-1">Convert</a>
              </a>
              <a href="https://www.soulrooms.com/engage">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit mb-1">Engage</a>
              </a>
              <a href="https://www.soulrooms.com/price">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit">Price</a>
              </a>
            </div>
            <div className="flex flex-col mb-4 lg:mt-4">
              <div className="font-extrabold mb-6" style={{ fontFamily: 'Oswald' }}>
                Get In Touch
              </div>
              <a href="https://www.soulrooms.com/request-demo">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit mb-1">Request Demo</a>
              </a>
              <a href="https://www.soulrooms.com/company">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit mb-1">Company</a>
              </a>
              {/* <a href="https://www.soulrooms.com/resources">
                <a className="text-gray-300 hover:text-yellow-500 hover:cursor-pointer w-fit">Resources</a>
              </a> */}
            </div>
            <div className="col-span-2 mt-4 lg:mt-0 flex flex-col text-white">
              <object
                aria-label="Star Icon"
                className="my-6 h-8 md:h-10 mr-auto"
                data="/static/tailwindAssets/Container / _Icon Holder.svg"></object>
              <div className="font-extrabold text-2xl md:text-4xl mb-4" style={{ fontFamily: 'Oswald' }}>
                Subscribe
              </div>
              <div className="mb-8">
                Get access to new resources and news about multi-family space straight to your inbox.
              </div>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  subscribeEmail();
                }}>
                <input
                  type={'email'}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="text-black py-2 px-4 mr-4 mb-2 w-[250px] max-w-full rounded"
                  placeholder="Please enter your email"
                />
                <button disabled={loading} type="submit" className="disabled:bg-gray-300 bg-yellow-500 py-2 px-4 rounded">
                  {loading? 'Subscribed' : 'Subscribe'}
                </button>
              </form>
            </div>
          </div>
          <div className="flex justify-between flex-wrap mx-2 py-2 border-t-2">
            <div className="text-center ">
              <ul className="ftco-footer-social mb-4  pt-3 pb-1 flex justify-center text-center list-unstyled">
                <li className="ftco-animate  px-1">
                  <a
                    href="https://www.facebook.com/soulroomstoronto/"
                    target="_blank"
                    rel="noreferrer"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Twitter">
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="28px"
                      height="40px"
                      data-ux="IconSocial"
                      className="x-el x-el-svg text-white hover:text-yellow-500">
                      <path
                        fillRule="evenodd"
                        d="M22 12.061C22 6.505 17.523 2 12 2S2 6.505 2 12.061c0
                        5.022 3.657 9.184 8.438 9.939v-7.03h-2.54v-2.91h2.54V9.845c0-2.522
                        1.492-3.915 3.777-3.915 1.094 0 2.238.197 2.238.197v2.476h-1.26c-1.243 0-1.63.775-1.63
                        1.57v1.888h2.773l-.443 2.908h-2.33V22c4.78-.755 8.437-4.917 8.437-9.939z"></path>
                    </svg>
                  </a>
                </li>
                <li className="ftco-animate px-1">
                  <a
                    href="https://twitter.com/soulroomsto"
                    target="_blank"
                    rel="noreferrer"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Facebook">
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="28px"
                      height="40px"
                      data-ux="IconSocial"
                      className="x-el x-el-svg text-white hover:text-yellow-500">
                      <path
                        d="M22 12c0-5.524-4.477-10-10-10C6.478 2 2 6.478 2 12c0 5.523 4.478 10 10 10 5.523
                       0 10-4.477 10-10zm-3.795-3.516c-.33.492-.749.926-1.23 1.271l.008.319c0 3.255-2.477
                       7.005-7.006 7.005a6.968 6.968 0 01-3.773-1.107 4.94 4.94 0 003.645-1.019 2.466
                       2.466 0 01-2.3-1.71 2.464 2.464 0 001.112-.042 2.465 2.465 0
                       01-1.975-2.413v-.032c.333.185.711.295 1.115.309a2.461 2.461 0
                        01-.762-3.286 6.987 6.987 0 005.075 2.572 2.462 2.462 0
                        014.196-2.245 4.986 4.986 0 001.564-.597A2.469 2.469 0
                        0116.79 8.87a4.936 4.936 0 001.414-.387z"></path>
                    </svg>
                  </a>
                </li>
                <li className="ftco-animate px-1">
                  <a
                    href="https://www.instagram.com/soulroomstoronto/"
                    target="_blank"
                    rel="noreferrer"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Instagram">
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="28px"
                      height="40px"
                      data-ux="IconSocial"
                      className="x-el x-el-svg text-white hover:text-yellow-500">
                      <path
                        d="M16.604 8.516c.13.35.198.719.203
                      1.091.033.622.033.811.033 2.386 0 1.574-.004
                      1.763-.033 2.385a3.273 3.273 0 0 1-.203 1.091
                      1.956 1.956 0 0
                       1-1.12 1.12c-.35.13-.719.198-1.091.204-.622.032-.811.032-2.386.032-1.574
                       0-1.763-.003-2.385-.032a3.273 3.273 0 0 1-1.091-.204 1.956 1.956 0 0 1-1.12-1.12 3.273
                        3.273 0 0 1-.204-1.09c-.032-.623-.032-.812-.032-2.386
                        0-1.575.003-1.764.032-2.386.006-.372.074-.741.204-1.09a1.956 1.956 0
                         0 1 1.12-1.12c.35-.13.718-.199 1.09-.204.623-.033.812-.033
                         2.386-.033 1.575 0 1.764.004 2.386.033.372.005.741.074
                         1.09.203.515.2.922.606 1.12 1.12zM12 15.033a3.033 3.033 0
                         1 0 0-6.066 3.033 3.033 0 0 0 0 6.066zm3.153-5.477a.71.71 0
                         1 0 0-1.418.71.71 0 0 0 0 1.418zM12 13.967a1.967 1.967 0 1 1
                          0-3.934 1.967 1.967 0 0 1 0 3.934zM12 2C6.477 2 2 6.477 2
                          12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2zm5.87
                          12.433c-.01.49-.102.974-.274 1.432a3.018 3.018 0 0 1-1.727
                           1.728 4.335 4.335 0 0 1-1.433.272c-.629.03-.829.037-2.432.037-1.604
                            0-1.819 0-2.433-.037a4.335 4.335 0 0 1-1.433-.272 3.018 3.018 0 0
                             1-1.727-1.728 4.335 4.335 0 0 1-.273-1.432c-.029-.63-.036-.83-.036-2.433
                             0-1.604 0-1.818.036-2.433.01-.49.102-.974.273-1.432a3.018 3.018 0 0 1
                             1.727-1.728 4.335 4.335 0 0 1 1.433-.272c.629-.03.829-.037 2.433-.037
                              1.603 0 1.818 0 2.432.037.49.009.974.101 1.433.272.794.307 1.42.934
                              1.727 1.728.172.458.264.943.273 1.432.03.63.036.83.036 2.433 0
                              1.604-.007 1.804-.036 2.433z"></path>
                    </svg>
                  </a>
                </li>
                <li className="ftco-animate px-1">
                  <a
                    href="https://www.linkedin.com/company/soulrooms/"
                    target="_blank"
                    rel="noreferrer"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Instagram">
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="28px"
                      height="40px"
                      data-ux="IconSocial"
                      className="x-el x-el-svg text-white hover:text-yellow-500">
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.524-4.477-10-10-10C6.478 2 2 6.478 2 12c0
                         5.523 4.478 10 10 10 5.523 0 10-4.477 10-10zm-5.074-6H6.871C6.391
                         6 6 6.38 6 6.85v10.098c0 .47.39.852.871.852h10.056a.864.864 0
                          00.873-.852V6.85a.863.863 0 00-.874-.85zm-8.3 1.621a1.015 1.015
                          0 11-.003 2.03 1.015 1.015 0 01.002-2.03zm-.876 2.803h1.753v5.63H7.75v-5.63zm2.85
                           0h-.002l.002-.002v.002zm0 0h1.677v.77h.023c.233-.443.804-.91 1.66-.91 1.771
                           0 2.098 1.166 2.098 2.682v3.089H14.31v-2.74c0-.652-.011-1.493-.909-1.493-.91
                            0-1.05.712-1.05 1.446v2.785H10.6v-5.63z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="self-center text-gray-400">2022 &copy;Soulrooms Inc. Toronto</div>
          </div>
        </div>
      </div>
    </>
  );
}
