import http from '../http';

/**
 * User Api Service
 */
export class UserApiService {
  urlPrefix = 'users';

  getCurrentUser() {
    return http.get(`${this.urlPrefix}/me`);
  }

  updateCurrentUser(data) {
    return http.put(`${this.urlPrefix}/me`, data);
  }

  getUser(userId) {
    return http.get(`${this.urlPrefix}/${userId}`);
  }

  subscribe(data) {
    return http.post(`${process.env.REACT_APP_API_URL || 'http://localhost:8080'}/${this.urlPrefix}/subscribe`, data);
  }

  contactUs(data) {
    return http.post(`${process.env.REACT_APP_API_URL || 'http://localhost:8080'}/${this.urlPrefix}/contactUs`, data);
  }

  // deleted getAllUser()

  initiateCustomScreening(data) {
    return http.post(`${this.urlPrefix}/screening/custom-initiate`, data);
  }

  initiateRentifyScreening(data) {
    return http.post(`${this.urlPrefix}/screening/rentify-initiate`, data);
  }

  screeningFinalize(screenId, data) {
    return http.post(`${this.urlPrefix}/screening/finalize?screenId=${screenId}`, data);
  }

  getScreeningData(userId) {
    return http.get(`${this.urlPrefix}/${userId}/screening`);
  }
  createRoomieProfile(data) {
    return http.put(`${this.urlPrefix}/me/profile`, data);
  }
}

export const UserApi = new UserApiService();
