import React from "react";

export default function Paragraph({ children, className, smaller,paddingClassName, style, textColorCssClassName }) {
  return (
    <p
      className={`paragraph ${paddingClassName || "mb-5"}  ${smaller? "text-lg": "text-xl" } ${textColorCssClassName || "text-gray-500"}  ${className || " "}`}
      style={{ fontFamily: "Josefin Sans", ...style }}
    >
      {children}
    </p>
  );
}
