import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Whisper, Popover } from "rsuite";
import detectWindowScreenSize from "../utils/detect-windows-size";
import DiscoverRentalsModal from "../components/modals/DiscoverRentals";

export default function Navbar(props) {
  const [width] = detectWindowScreenSize();
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const toggleHamburgerMenu = () => setHamburgerMenu(!hamburgerMenu);
  const [openDiscoverRentalsModal, setOpenDiscoverRentalsModal] =
    useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const productsSpeaker = (
    <Popover>
      <div className="text-gray-500" style={{ fontFamily: "Josefin Sans" }}>
        <div>
          <Link to="/analytics">
            <button className="my-auto p-3 hover:text-yellow-500">
              Analytics
            </button>
          </Link>
        </div>
        <div>
          <Link to="/convert">
            <button className="my-auto p-3 hover:text-yellow-500">
              Convert
            </button>
          </Link>
        </div>
        <div>
          <Link to="/engage">
            <button className="my-auto p-3 hover:text-yellow-500">
              Engage
            </button>
          </Link>
        </div>
        <div>
          <Link to="/price">
            <button className="my-auto p-3 hover:text-yellow-500">Price</button>
          </Link>
        </div>
      </div>
    </Popover>
  );

  const solutionsSpeaker = (
    <Popover className="text-gray-500" style={{ fontFamily: "Josefin Sans" }}>
      <div>
        <div>
          <Link to="/enterprise-multi-family">
            <button className="my-auto p-3 hover:text-yellow-500">
              Enterprise Multifamily
            </button>
          </Link>
        </div>
        <div>
          <Link to="/students-and-co-living">
            <button className="my-auto p-3 hover:text-yellow-500">
              Coliving & Student Housing
            </button>
          </Link>
        </div>
      </div>
    </Popover>
  );

  return (
    <div style={{ fontFamily: "Josefin Sans" }}>
      {openDiscoverRentalsModal && (
        <DiscoverRentalsModal
          show={openDiscoverRentalsModal}
          setClose={setOpenDiscoverRentalsModal}
        />
      )}
      {/* Static desktop version */}
      {props.version === "static" && width >= 820 && (
        <div className="bg-white">
          <div className="w-full flex flex-row justify-between fixed top-0 z-50 bg-white py-4 mx-auto items-center">
            <a href="/" className="ml-14 self-center">
              <img
                src="/static/images/landingPages/UkraineLogo.png"
                className="h-16"
                alt="SoulRooms Logo"
              />
            </a>
            <div className="flex flex-row justify-between mr-14 text-base">
              <a href="https://www.soulrooms.com" target="_blank">
                <button className="my-auto p-3 text-black hover:text-yellow-500">
                  Company Website
                </button>
              </a>
              <Link to="/">
                <button className="my-auto p-3 text-black hover:text-yellow-500">
                  Overview
                </button>
              </Link>
              <Link to="/get-involved">
                <button className="my-auto p-3 text-black hover:text-yellow-500">
                  Get Involved
                </button>
              </Link>
              <Link to="/about-us">
                <button className="my-auto p-3 text-black hover:text-yellow-500">
                  Who We Are
                </button>
              </Link>
              <button
                className="my-auto py-2 px-8 font-semibold text-yellow-500 bg-black rounded-md hover:text-black hover:bg-yellow-500 leading-normal"
                onClick={() => setOpenDiscoverRentalsModal(true)}
              >
                Discover Rentals
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Static mobile version */}
      {props.version === "static" && width < 820 && (
        <div className="w-full fixed top-0 z-50 bg-white py-4">
          <div className="w-full flex flex-row justify-between pt-2">
            <a href="/" className="ml-3 self-center">
              <img
                src="/static/images/landingPages/UkraineLogo.png"
                className="h-14"
                alt="SoulRooms Logo"
              />
            </a>
            <div className="flex flex-row">
              <button
                className="my-auto p-2 mr-3 font-semibold text-yellow-500 bg-black rounded-lg hover:text-black hover:bg-yellow-500"
                onClick={() => setOpenDiscoverRentalsModal(true)}
              >
                Discover Rentals
              </button>
              <button
                onClick={toggleHamburgerMenu}
                type="button"
                className="mr-3 p-2 my-auto text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                {hamburgerMenu ? (
                  // Show X icon if the hamburger menu is open
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                ) : (
                  // Show menu icon if the hamburger menu is closed
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                )}
              </button>
            </div>
          </div>
          {/* Static mobile hamburger menu */}
          {hamburgerMenu && (
            <div className="w-full">
              <a href="https://www.soulrooms.com">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3">Company Website</button>
                </div>
              </a>
              <Link to="/get-involved">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3">Get Involved</button>
                </div>
              </Link>
              <Link to="/about-us">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3" type="button">
                    Who We Are
                  </button>
                </div>
              </Link>
            </div>
          )}
        </div>
      )}
      {/* Login desktop version */}
      {props.version === "login" && width >= 965 && (
        <div
          className={`w-full flex flex-row fixed top-0 z-50 ${
            offset > 70 ? "bg-white" : "bg-transparent"
          } item-center py-4`}
        >
          <div className="flex flex-row justify-between flex-1 max-w-[1600px] mx-auto">
            <div className="flex flex-row justify-between space-x-1 ml-5">
              <a href="/" className="ml-14">
                <img
                  src="/static/images/landingPages/logo.png"
                  className="h-6"
                  alt="SoulRooms Logo"
                />
              </a>
              <div className="my-auto px-3 text-sm cursor-default text-gray-500 hover:text-yellow-500">
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={productsSpeaker}
                  enterable
                >
                  <div>Products</div>
                </Whisper>
              </div>
              <div className="my-auto px-3 text-sm cursor-default text-gray-500 hover:text-yellow-500">
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={solutionsSpeaker}
                  enterable
                >
                  <div>Solutions</div>
                </Whisper>
              </div>
              {/* <Link to="/resources">
                <button className="my-auto px-3 text-sm text-gray-500 hover:text-yellow-500">Resources</button>
              </Link> */}
              <Link to="/company">
                <button className="my-auto px-3 text-sm text-gray-500 hover:text-yellow-500">
                  Company
                </button>
              </Link>
            </div>
            <div className="flex flex-row justify-between mr-14 text-base items-center">
              <Link to="/renters">
                <button
                  className={`my-auto px-2 py-0 text-black text-sm border-r-2 hover:border-black border-current ${
                    offset > 70 || !props.landingNav
                      ? "hover:text-yellow-500"
                      : "hover:text-white"
                  }`}
                >
                  I'm a renter
                </button>
              </Link>
              <Link to="/login">
                <button
                  className={`my-auto px-2 py-0 mr-5 text-black text-sm ${
                    offset > 70 || !props.landingNav
                      ? "hover:text-yellow-500"
                      : "hover:text-white"
                  }`}
                >
                  Login
                </button>
              </Link>
              <Link to="/request-demo">
                <button className="my-auto mr-14 py-2 px-6 text-yellow-500 bg-black rounded-lg shadow-md hover:text-black hover:bg-yellow-500">
                  Request Demo
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      {/* Login mobile version */}
      {props.version === "login" && width < 965 && (
        <div className="w-full fixed top-0 z-50 bg-white py-4 max-w-[1600px]">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="ml-4">
              <a href="/" className="">
                <img
                  src="/static/images/landingPages/logo.png"
                  className="h-5"
                  alt="SoulRooms Logo"
                />
              </a>
            </div>
            <div className="flex">
              <div className="w-full">
                <button className="py-2 px-4 mr-3 text-yellow-500 bg-black rounded-lg shadow-md hover:text-black hover:bg-yellow-500">
                  Request Demo
                </button>
              </div>
              <div className="my-auto">
                <button
                  onClick={toggleHamburgerMenu}
                  type="button"
                  className="mr-3 px-2 text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                >
                  {hamburgerMenu ? (
                    // Show X icon if the hamburger menu is open
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  ) : (
                    // Show menu icon if the hamburger menu is closed
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          {/* Static mobile hamburger menu */}
          {hamburgerMenu && (
            <div className="w-full">
              <Link to="/products">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3">Products</button>
                </div>
              </Link>
              <Link to="/analytics">
                <div className="bg-gray-200 hover:text-yellow-500">
                  <button className="my-auto pl-6 py-3 pr-3">Analytics</button>
                </div>
              </Link>
              <Link to="/convert">
                <div className="bg-gray-200 hover:text-yellow-500">
                  <button className="my-auto pl-6 py-3 pr-3">Convert</button>
                </div>
              </Link>
              <Link to="/engage">
                <div className="bg-gray-200 hover:text-yellow-500">
                  <button className="my-auto pl-6 py-3 pr-3">Engage</button>
                </div>
              </Link>
              <Link to="/price">
                <div className="bg-gray-200 hover:text-yellow-500">
                  <button className="my-auto pl-6 py-3 pr-3">Price</button>
                </div>
              </Link>
              <Link to="/solutions">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3">Solutions</button>
                </div>
              </Link>
              <Link to="/enterprise-multi-family">
                <div className="bg-gray-200 hover:text-yellow-500">
                  <button className="my-auto pl-6 py-3 pr-3">
                    Enterprise Multifamily
                  </button>
                </div>
              </Link>
              <Link to="/students-and-co-living">
                <div className="bg-gray-200 hover:text-yellow-500">
                  <button className="my-auto pl-6 py-3 pr-3">
                    Coliving & Student Housing
                  </button>
                </div>
              </Link>
              {/* <Link to="/resources">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3 ">Resources</button>
                </div>
              </Link> */}
              <Link to="/company">
                <div className="bg-gray-100 hover:text-yellow-500">
                  <button className="my-auto p-3 ">Company</button>
                </div>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
