import React from "react";
import PageSection from "./PageSection";

export default function PageSectionWithTwoColumns({
  className,
style,
  hideSecondSectionOnSmallScreen,
  showSecondSectionFirst,
  fullscreen,
  noMarginNextToSecondSection,
  increaseSecondSectionSize,
  children,
}) {
  return (
    <PageSection style={style}
      className={`text-center ${
        noMarginNextToSecondSection &&
        (showSecondSectionFirst ? " md:pl-0 " : " md:pr-0 ")
      } ${className}`}
    >
      <section
        className={`text_and_image_section flex flex-col ${
          showSecondSectionFirst ? " lg:flex-row-reverse " : " lg:flex-row "
        }   ${fullscreen && "lg:h-screen"} `}
      >
        <div
          className={`text_section alignment_center text-left flex-auto flex flex-col justify-center align-center  ${
            increaseSecondSectionSize ? "md:basis-1/3" : "md:basis-2/4"
          } `}
        >
          {children[0]}
        </div>

        <div
          className={`image_section ${
            showSecondSectionFirst ? " mb-10 md:mb-0 " : " mt-10 md:mt-0 "
          }  ${
            hideSecondSectionOnSmallScreen ? " hidden lg:flex " : " flex "
          } flex-auto flex-col justify-center align-center 
            ${increaseSecondSectionSize ? "md:basis-2/3" : "md:basis-2/4"}
            ${noMarginNextToSecondSection ? " md:p-0 " : " md:p-10 "} `}
        >
          {children[1]}
        </div>
      </section>
    </PageSection>
  );
}
