import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default function ImageTitleLink({ imgSrc, header, link }) {
  return (
    <div className="flex flex-col w-auto">
      <div className="flex h-72 w-72">
        <img className="image_el flex-auto bg-slate-100 outline-0" src={imgSrc} alt="" />
      </div>
      <div className="py-5">
        <h1 className="font-semibold text-3xl text-black" style={{ fontFamily: 'Oswald' }}>
          {header}
        </h1>
      </div>
      <a style={{ fontFamily: 'Josefin Sans' }} href={link} className={`text-xl text-black items-center `}>
        <span className="mr-2">Learn more</span> <FontAwesomeIcon icon={faAngleRight} />
      </a>
    </div>
  );
}
