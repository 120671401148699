import React from 'react';

export default function Stats({ statNum, statTitle }) {
  return (
    <div className="flex flex-col w-full items-center">
      <div>
        <h1 className="py-10 font-bold text-7xl">{statNum}</h1>
      </div>
      <div>
        <h1 className="font-bold text-2xl underline" style={{ fontFamily: 'Oswald' }}>{statTitle}</h1>
      </div>
    </div>
  );
}
